@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&family=Karla:ital,wght@0,200..800;1,200..800&family=Rubik+Moonrocks&display=swap');

$theme-colors: (
    "header": 'lightgrey',
    "footer": 'lightgrey',
    "main": 'purple'
);

.loader-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #eff1f3 transparent #eff1f3 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

body, html {
    // padding: 20px;
    width: 100vw;
    padding: 0;
    font-family: "Libre Baskerville, serif" !important; 
    scroll-behavior: smooth;
}

@keyframes slideInFromLeftHeader {
    0% {
      transform: translateY(-70px);
    }
    100% {
      transform: translateX(0);
    }
  }

.animatedHero {
    animation: 1s ease-out 0s 1 slideInFromLeftHeader;
}

@keyframes slideInFromLeftIcon {
    0% {
      transform: translateX(-60px);
    }
    100% {
      transform: translateY(0);
    }
  }

.shopAll, .socials {
    animation: 1s ease-out 0s 1 slideInFromLeftIcon;
}

.socials:hover {
    border-bottom: 1px solid grey;
}


.todayPickCard{
    border-radius: 10%;
    box-shadow: 0px 3px 16px rgba(47, 83, 109, 0.12);
}

.todayPickCard:hover{
    transition: all 0.3s ease-in-out;
    transform: translateY(-10px);
}

@media (min-width: 768px) and (max-width:  1399.98px) { 
    .category {
        width: "400px"
    }
}
